<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="phone_input">
                <phone-number
                    @phoneValidated="validatePhone"
                />
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { createNamespacedHelpers, mapState } from 'vuex';
import { eventBus } from '@/helpers/event-bus';
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/phone/Rules.js'
import { formMessages } from '@/validation/phone/Messages.js'

import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import {
    ACTION_CLIENT_UPDATE_PHONE
} from '@/store/modules/client/action-types';
import { mapPhoneFields } from '@/store/modules/phone'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    components: {
        SettingBlockLayout,
        PhoneNumber
    },
    metaInfo: {
        title: 'Change phone number'
    },
    validations: {
        ...validationRules
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState('phone', {
            started: 'started',
            verified: 'verified'
        }),
        ...mapPhoneFields([
            'form_data.country',
            'form_data.phone_number'
        ])
    },
    methods: {
        ...mapClientActions({
            updatePhone: ACTION_CLIENT_UPDATE_PHONE
        }),
        validationMessage: validationMessage(formMessages),
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        async onSave() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
                return
            }
            try {
                this.loading = true
                await this.updatePhone({
                    phone_code: this.country.value,
                    phone: this.phone_number
                })
                eventBus.$emit('showSnackBar', 'Your phone has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            } finally {
                this.loading = false
            }
        },
        check() {
            if (!this.phoneValidationStatus) {
                this.validate()
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        }
    }
}
</script>

<style lang="scss">
    .phone_input{
        display: flex;
        justify-content: center;
        width: 50%;
        margin: 0 auto;
        .password_field{
            width: 100%;
        }
        @media all and (max-width: 768px){
            width: 100%;
        }
    }
</style>
